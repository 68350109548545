import { ReactDatePickerProps } from 'react-datepicker'
import Utils from '../../services/utils'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { StandardAttributes } from '../../types'
import { Warning } from '@phosphor-icons/react'
import { DateField } from './DateField'

export const DateRange = (
    props: StandardAttributes & {
        id?: string
        datePickerProps?: Partial<ReactDatePickerProps>
        inputStyle?: CSSProperties
        startDate?: Date
        endDate?: Date
        minDate?: Date
        maxDate?: Date
        highlightFutureDates?: boolean
        changeOnBlurKeyDown?: boolean
        onChanged?: (startDate?: Date | null, endDate?: Date | null) => void
    },
) => {
    const [hasChanged, setHasChanged] = useState(false)
    const [startDate, setStartDate] = useState<Date | null | undefined>(props.startDate)
    const [endDate, setEndDate] = useState<Date | null | undefined>(props.endDate)

    useEffect(() => {
        setStartDate(props.startDate)
        setEndDate(props.endDate)
    }, [props.startDate, props.endDate])

    const dateInTheFutureMessage = useMemo(
        () => (
            <span className='d-block text-warning'>
                <Warning /> Date is in the future
            </span>
        ),
        [],
    )
    const startDateInTheFuture = useMemo(
        () => props.highlightFutureDates !== false && Utils.dayjs(startDate).isAfter(Utils._dayjs().endOf('day')),
        [props.highlightFutureDates, startDate],
    )
    const endDateInTheFuture = useMemo(
        () => props.highlightFutureDates !== false && Utils.dayjs(endDate).isAfter(Utils._dayjs().endOf('day')),
        [props.highlightFutureDates, endDate],
    )

    const className = useMemo(() => 'variable-form-control text-primary font-monospace w-100 text-center', [])

    return (
        <div
            className={[
                props.className || 'd-flex align-items-center justify-content-start gap-1 w-100',
                props.extraClassName,
            ].join(' ')}
        >
            <div style={{ maxWidth: '6rem', ...props.inputStyle }}>
                <DateField
                    id={props.id}
                    placeholderText='Start date'
                    popperPlacement='bottom-start'
                    toggleCalendarOnIconClick={true}
                    className={[className, startDateInTheFuture ? 'bg-warning bg-opacity-10' : 'bg-light'].join(' ')}
                    disabled={props.disabled}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    selected={startDate}
                    onChange={(date, e) => {
                        const tagName = (e?.target as HTMLElement)?.tagName
                        setStartDate(date)
                        const _hasChanged = date !== props.startDate
                        if ((!props.changeOnBlurKeyDown || tagName === 'DIV') && props.onChanged && _hasChanged) {
                            props.onChanged(date, endDate)
                        } else {
                            setHasChanged(_hasChanged)
                        }
                    }}
                    onBlur={() => {
                        if (hasChanged) props.onChanged?.(startDate, endDate)
                        setHasChanged(false)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (hasChanged) props.onChanged?.(startDate, endDate)
                            setHasChanged(false)
                        }
                    }}
                    selectsStart={true}
                    {...props.datePickerProps}
                >
                    {startDateInTheFuture ? dateInTheFutureMessage : null}
                </DateField>
            </div>
            <span>–</span>
            <div style={{ maxWidth: '6rem', ...props.inputStyle }}>
                <DateField
                    placeholderText='End date'
                    popperPlacement='bottom-start'
                    toggleCalendarOnIconClick={true}
                    className={[className, endDateInTheFuture ? 'bg-warning bg-opacity-10' : 'bg-light'].join(' ')}
                    disabled={props.disabled}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    selected={endDate}
                    onChange={(date, e) => {
                        const tagName = (e?.target as HTMLElement)?.tagName
                        setEndDate(date)
                        const _hasChanged = date !== props.endDate
                        if ((!props.changeOnBlurKeyDown || tagName === 'DIV') && props.onChanged && _hasChanged) {
                            props.onChanged(startDate, date)
                        } else {
                            setHasChanged(_hasChanged)
                        }
                    }}
                    onBlur={() => {
                        if (hasChanged) props.onChanged?.(startDate, endDate)
                        setHasChanged(false)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            if (hasChanged) props.onChanged?.(startDate, endDate)
                            setHasChanged(false)
                        }
                    }}
                    selectsEnd={true}
                    {...props.datePickerProps}
                >
                    {endDateInTheFuture ? dateInTheFutureMessage : null}
                </DateField>
            </div>
        </div>
    )
}
