import { ActionMap } from '../context'
import { Company, UserRole } from './company'
import { Token } from './token'
import { KeyValuePair, VariableBaseNode } from '../types'
import { Org } from './org'

export type UserType = 'standard' | 'guest'

export interface UserWithoutID extends VariableBaseNode {
    email: string
    emailVerified?: boolean
    firstName: string
    lastName: string
    type?: UserType
    positionRole?: UserPositionRole
    title?: string
    expires?: number
    companies?: Company[]
    orgs?: Org[]
    superPowers?: SuperPower[]
    unitPreferences?: UnitPreferences
    uiPreferences?: UiPreferences
    sessions?: Token[]
    role?: UserRole
    profileImageUrl?: string
    lastLogin?: number
    previousLogin?: number
    lastApiAccess?: number
    permissions?: Permission[]
    deleted?: number // timestamp
}

export interface UnitPreferences {
    [unitType: string]: string
}

export enum Permission {
    PACT_READ = 'pact:read',
    ACTIVITY_READ = 'activity:read',
    ACTIVITY_DATA_READ = 'activity.data:read',
    ORG_READ = 'org:read',
    SCOPE_READ = 'scope:read',
    TAXONOMY_READ = 'taxonomy:read',
    PRODUCT_READ = 'product:read',
    ELEMENT_READ = 'element:read',
    MODEL_READ = 'model:read',
}

export type UserPositionRole =
    | 'sustainability'
    | 'r_and_d'
    | 'sourcing_procurement'
    | 'operations'
    | 'finance'
    | 'ceo'
    | 'other'

export interface UiPreferences {
    startWeekOn?: 'sunday' | 'monday'
    timezone?: string
    locale?: string
    dateFormat?: string
    timeFormat?: '12h' | '24h'
    numberFormat?: string
    currency?: string
    theme?: string
    language?: string
}

export interface UserPositionRoleItem extends KeyValuePair {
    value: UserPositionRole
}

export const userPositionRoles: UserPositionRoleItem[] = [
    { name: 'Sustainability', value: 'sustainability' },
    { name: 'R&D', value: 'r_and_d' },
    { name: 'Sourcing / Procurement', value: 'sourcing_procurement' },
    { name: 'Operations', value: 'operations' },
    { name: 'Finance', value: 'finance' },
    { name: 'CEO', value: 'ceo' },
    { name: 'Other', value: 'other' },
]

export enum SuperPowers {
    GLOBAL_ADMIN = 'global-admin',
}

export interface SuperPower {
    name?: string
    slug: SuperPowers
}

export type User = UserWithoutID & {
    uuid: string
    authId?: string
    exp?: number // jwt expiration
}

export type Contact = UserWithoutID & {
    uuid?: string
    email?: string
}

export type Creator = {
    firstName: string
}

export const EmptyUser: UserWithoutID = {
    firstName: '',
    lastName: '',
    email: '',
}

export enum UserActionType {
    Set = 'SetUser',
    SetAccounts = 'SetUserAccounts',
    Reset = 'ResetUser',
}

type UserActionPayload = {
    [UserActionType.Set]: User
    [UserActionType.Reset]: undefined
    [UserActionType.SetAccounts]: Company[] | undefined
}

/** Possible operations on the `User` store with typed payload. */
export type UserActions = ActionMap<UserActionPayload>[keyof ActionMap<UserActionPayload>]

const UserReducer = (state: User | null, action: UserActions): User | null => {
    if (action.type === UserActionType.SetAccounts) {
        if (state) {
            return { ...state, companies: action.payload }
        } else {
            return null
        }
    } else if (action.type === UserActionType.Set) {
        try {
            action.payload.unitPreferences = JSON.parse((action.payload.unitPreferences || '{}') as string)
        } catch (e) {
            // do nothing.
        }
        try {
            action.payload.uiPreferences = JSON.parse((action.payload.uiPreferences || '{}') as string)
        } catch (e) {
            // do nothing.
        }
        return { ...action.payload }
    }
    switch (action.type) {
        case UserActionType.Reset:
            return null
        default:
            return state
    }
}

export { UserReducer }
