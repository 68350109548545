import { DataSource } from './dataSource'
import { Company } from './company'
import UnitService, { Unit } from './unit'
import ProductService, { DataQuality, Product, ProductFootprintType, ProductVisibility } from './product'
import PartService, { Part, PartType } from './part'
import { ActionMap } from '../context'
import Utils from './utils'
import { Tag } from './tag'
import { NavItem } from './ui'
import { Amount, KeyValuePair, ND, StandardAttributes, UsedIn, VariableBaseNode, VariableNodeType } from '../types'
import VariableService from './service'
import { Taxonomy } from './taxonomy'
import TransportService, { TransportType } from './transport'
import { IconProps } from '@phosphor-icons/react'
import LocationService, { Location } from './location'
import { GeoLocation } from './geoLocation'

export enum InventoryType {
    All = 'All',
    Product = 'Product',
    Part = 'Part',
    Transport = 'Transport',
    Energy = 'Energy',
    Navigation = 'Navigation',
    Location = 'Location',
}

export interface Inventory extends VariableBaseNode {
    name: string
    labels?: string[]
    ids?: string[]
    keyboardShortcut?: string
    keywords?: string
    hasCo2e?: boolean
    co2e?: string | null
    functionalUnit?: string
    aka?: string[]
    tags?: Tag[]
    description?: string
    isSolution?: boolean
    isVerified?: boolean
    image?: string
    type: InventoryType
    typeString: string
    dataSources?: DataSource[]
    productOf?: Company
    taxonomy?: Taxonomy
    unit?: Unit
    weight?: Amount
    url: string
    startDate?: number
    endDate?: number
    quality?: DataQuality
    dataImportId?: string
    originalProduct?: Product
    originalPart?: Part
    originalTransportType?: TransportType
    originalLocation?: Location
    usedIn?: UsedIn[]
    activityCount?: number
    sourceForCount?: number
    partOfCount?: number
}

export interface CreateProductFrom {
    id: string
    properties?: Partial<Product>
    instanceId?: string
}

export type ProductUsageProps = StandardAttributes & {
    inventory?: Inventory
    product?: Product
    part?: Part
    location?: Location
    transportType?: TransportType
    usedIn?: UsedIn[]
    activityCount?: number
    activityCo2e?: number
    showTooltip?: boolean
    iconProps?: IconProps
}

export interface InventoryContext {
    createFrom?: CreateProductFrom
    createdFrom?: Product
    updated?: number
}

export enum InventoryActionType {
    Merge = 'MergeInventory',
    RemoveById = 'RemoveInventoryById',
    SetCreateFrom = 'SetCreateFrom',
    SetCreatedFrom = 'SetCreatedFrom',
}

type InventoryActionPayload = {
    [InventoryActionType.Merge]: Inventory[]
    [InventoryActionType.RemoveById]: string
    [InventoryActionType.SetCreateFrom]: CreateProductFrom | undefined
    [InventoryActionType.SetCreatedFrom]: Product | undefined
}

export type InventoryActions = ActionMap<InventoryActionPayload>[keyof ActionMap<InventoryActionPayload>]

export const InventoryReducer = (state: InventoryContext, action: InventoryActions): InventoryContext => {
    if (action.type === InventoryActionType.Merge) {
        InventoryService.mergeInventory(action.payload)
        state.updated = Date.now()
    } else if (action.type === InventoryActionType.RemoveById) {
        InventoryService.byId.delete(action.payload)
        state.updated = Date.now()
    } else if (action.type === InventoryActionType.SetCreateFrom) {
        state.createFrom = action.payload
    } else if (action.type === InventoryActionType.SetCreatedFrom) {
        state.createdFrom = action.payload
    }
    InventoryService.list = Array.from(InventoryService.byId.values())
    return state
}

export interface InventoryStats {
    inventory?: Inventory
    usedIn: UsedIn[]
    sourceFor?: UsedIn[]
    sourceForCount: number
    partOf?: UsedIn[]
    partOfCount: number
    activityCount: number
}

export type InventoryListColumns = 'name' | 'taxonomy' | 'source' | 'usage' | 'dataQuality' | 'type' | 'co2e'

export class InventoryService extends VariableService {
    public static webTitle = () => 'Inventory'
    public static webRoot: string = '/inventory'
    public static webTitleMaterial = () => 'Material'
    public static webRootMaterial: string = '/material'
    public static webRootArchive: string = '/archive'

    public static currentView: string | undefined = undefined
    public static list: Inventory[] = []
    public static byId: Map<string, Inventory> = new Map()
    public static hasArchived: boolean = false

    public static mergeInventory(inventory: Inventory[]) {
        inventory.forEach((inv) => {
            if (!inv.uuid || inv.labels?.includes(ND.EmissionFactor)) return
            if (!inv.uuid) return
            InventoryService.byId.set(inv.uuid, { ...InventoryService.byId.get(inv.uuid), ...inv })
            if (!InventoryService.hasArchived && inv.originalProduct?.visibility === ProductVisibility.ARCHIVED) {
                InventoryService.hasArchived = true
            }
        })
    }

    public inventoryTypes: KeyValuePair[] = [
        { name: TransportService.webTitle(), value: 'transport' },
        { name: PartService.getPartTypeString(PartType.SWITCH), value: PartType.SWITCH },
        { name: PartService.getPartTypeString(PartType.MIX), value: PartType.MIX },
        { name: PartService.getPartTypeString(PartType.CONFIG), value: PartType.CONFIG },
        {
            name: ProductService.getProductFootprintTypeString(undefined, ProductFootprintType.STATIC),
            value: ProductFootprintType.STATIC,
        },
        {
            name: ProductService.getProductFootprintTypeString(undefined, ProductFootprintType.FACTOR),
            value: ProductFootprintType.FACTOR,
        },
        {
            name: ProductService.getProductFootprintTypeString(undefined, ProductFootprintType.LIVE),
            value: ProductFootprintType.LIVE,
        },
        { name: 'Unknown', value: 'unknown' },
    ]

    public static defaultColumns: InventoryListColumns[] = [
        'name',
        'taxonomy',
        'source',
        'usage',
        'dataQuality',
        'type',
        'co2e',
    ]

    public static setCurrentView(view: string) {
        if (this.currentView === view) return
        this.currentView = view
    }

    public static getCurrentView(): string {
        return this.currentView || this.webRoot
    }

    public setCreateFrom(createFrom?: CreateProductFrom) {
        this.context.dispatch({ type: InventoryActionType.SetCreateFrom, payload: createFrom })
        this.context.dispatch({ type: InventoryActionType.SetCreatedFrom, payload: undefined })
    }

    public setCreatedFrom(product?: Product) {
        if (!product || this.context.stores.inventory.createdFrom?.uuid) return
        this.context.dispatch({ type: InventoryActionType.SetCreateFrom, payload: undefined })
        this.context.dispatch({ type: InventoryActionType.SetCreatedFrom, payload: product })
        this.context.dispatch({
            type: InventoryActionType.Merge,
            payload: [InventoryService.productToInventory(product)],
        })
    }

    public static getInventoryItem(
        item?: Product | Part | TransportType | GeoLocation | Location,
        type?: VariableNodeType,
    ): Inventory | undefined {
        if (type === 'Product') return InventoryService.productToInventory(item as Product)
        if (type === 'Part') return InventoryService.partToInventory(item as Part)
        if (type === 'TransportType') return InventoryService.transportToInventory(item as TransportType)
        if (type === 'Location') return InventoryService.locationToInventory(item as Location)
        return undefined
    }

    public static getInventoryUrl(inventory: Inventory): string {
        if (inventory.originalPart) {
            return PartService.getPartUrl(inventory.originalPart)
        } else if (inventory.originalProduct) {
            return ProductService.getProductUrl(inventory.originalProduct)
        } else if (inventory.originalTransportType) {
            return TransportService.getTransportTypeUrl(inventory.originalTransportType)
        } else if (inventory.originalLocation) {
            // TODO: Implement location URL
            return ''
        }
        return ''
    }

    public static getInventoryStats(opts: ProductUsageProps): InventoryStats {
        let inventory: Inventory | undefined = opts.inventory
        if (!inventory) {
            if (opts.product) inventory = InventoryService.productToInventory(opts.product)
            else if (opts.part) inventory = InventoryService.partToInventory(opts.part)
            else if (opts.transportType) inventory = InventoryService.transportToInventory(opts.transportType)
            else if (opts.location) inventory = InventoryService.locationToInventory(opts.location)
        }
        if (!inventory && !opts.usedIn) return { usedIn: [], sourceForCount: 0, partOfCount: 0, activityCount: 0 }

        const usedIn = opts.usedIn || inventory?.usedIn || []

        const partOf = usedIn?.filter((ui) => ['Product', 'TransportType'].includes(ui.type)) || []
        const sourceFor: UsedIn[] = usedIn?.filter((ui) => ['Part'].includes(ui.type)) || []

        return {
            inventory,
            usedIn,
            sourceFor,
            sourceForCount: sourceFor.length || inventory?.sourceForCount || 0,
            partOf,
            partOfCount: partOf.length || inventory?.partOfCount || 0,
            activityCount: opts.activityCount || inventory?.activityCount || 0,
        }
    }

    public static navItemToInventory(i: NavItem, idx: number): Inventory {
        return {
            uuid: `${Utils.apocSlugify(i.text)}-${idx}`,
            type: InventoryType.Navigation,
            name: i.text,
            keywords: i.keywords,
            url: i.target || i.url || '',
            typeString: i.typeString,
            keyboardShortcut: i.keyboardShortcut,
        }
    }

    public static transportToInventory(t: TransportType): Inventory {
        return {
            uuid: t.uuid,
            type: InventoryType.Transport,
            co2e: t.co2e,
            unit: UnitService.unitByCode['tkm'],
            name: TransportService.getTransportTypeName(t),
            keywords: t.summary,
            url: TransportService.getTransportTypeUrl(t),
            taxonomy: t.taxonomy,
            usedIn: t.partOf?.map((p) => ({ uuid: p.uuid!, name: p.name, type: 'Product' })) || [],
            partOfCount: t.partOfCount || 0,
            activityCount: t.activityCount || 0,
            typeString: 'Transport',
            originalTransportType: t,
            dataImportId: t.dataImportId,
            created: t.created,
            updated: t.updated,
        }
    }

    public static locationToInventory(l: Location): Inventory {
        return {
            uuid: l.uuid,
            type: InventoryType.Location,
            name: LocationService.getLocationName(l),
            url: '',
            usedIn: l.usedIn,
            partOfCount: l.usedIn?.length || 0,
            typeString: 'Location',
            originalLocation: l,
            created: l.created,
            updated: l.updated,
        }
    }

    public static partToInventory(p: Part): Inventory {
        return {
            uuid: p.uuid,
            type: InventoryType.Part,
            name: p.name || `(Unnamed ${PartService.webTitle()})`,
            image: p.imagePath,
            hasCo2e: p.liveCo2e !== null,
            co2e: p.liveCo2e,
            unit: p.unit,
            weight: p.weight,
            usedIn: p.usedIn || [],
            activityCount: p.activityCount || 0,
            partOfCount: p.partOfCount || 0,
            typeString: PartService.getPartString(p),
            url: PartService.getPartUrl(p),
            originalPart: p,
            created: p.created,
            updated: p.updated,
        }
    }

    public static productToInventory(p: Product): Inventory {
        let ids = []
        if (p.uuid) ids.push(p.uuid)
        if (p.syncId) ids.push(p.syncId)
        if (p.sku) ids.push(p.sku)
        return {
            uuid: p.uuid,
            ids: ids,
            type: InventoryType.Product,
            name: p.name || '(Unnamed Footprint)',
            labels: p.labels,
            aka: p.aka,
            tags: p.tags,
            description: p.description,
            image: p.productImageUrl,
            isSolution: p.isSolution,
            isVerified: p.isVerified,
            hasCo2e: p.hasCo2e,
            co2e: p.co2e,
            productOf: p.productOf || undefined,
            unit: p.unit,
            weight: p.weight,
            dataSources: p.dataSources,
            taxonomy: p.taxonomy || undefined,
            typeString: ProductService.getProductFootprintTypeString(p),
            quality: p.quality,
            url: ProductService.getProductUrl(p),
            originalProduct: p,
            usedIn: p.usedIn,
            activityCount: p.activityCount || 0,
            sourceForCount: p.sourceForCount || 0,
            partOfCount: p.partOfCount || 0,
            dataImportId: p.dataImportId,
            startDate: p.startDate,
            endDate: p.endDate,
            created: p.created,
            updated: p.updated,
        }
    }

    public getParts(partType?: PartType): Inventory[] {
        return InventoryService.list.filter((p) => {
            if (partType && p.originalPart?.type !== partType) {
                return false
            }
            return p.originalPart?.uuid !== undefined
        })
    }

    public getProducts(productOf?: Company): Inventory[] {
        if (!productOf) productOf = this.context.stores.company
        return InventoryService.list.filter((p) => p.productOf?.uuid === productOf?.uuid)
    }

    public static inventoryMatch(filterText: string, i: Inventory): boolean | undefined {
        const _filterText = filterText.toLowerCase()
        const searchData = [
            i.uuid,
            i.name,
            i.keywords,
            i.aka,
            i.tags,
            i.productOf?.name,
            i.dataSources?.[0]?.name,
            i.unit?.name,
            i.unit?.code,
            i.description,
        ]
        if (i.ids) searchData.push(...i.ids)
        return Utils.filterItem(searchData, _filterText)
    }
}
