import ProductService, { DataQuality, Product, ProductFootprintType } from '../../services/product'
import { useCallback, useContext, useEffect, useState } from 'react'
import InputService, { Input } from '../../services/input'
import { InputItem } from './InputItem'
import Tooltip from '../Tooltip'
import CO2e from '../CO2e'
import { UnitHeader } from '../UnitHeader'
import Utils, { BomSortByOption } from '../../services/utils'
import Spinner from '../Spinner'
import PartService, { Part } from '../../services/part'
import Button from '../Input/Button'
import { ModelConfigContext } from './ModelConfig'
import { Selector } from '../Input/Selector'
import { onboardingGuesses } from '../../services/onboarding'
import { useOnboarding } from '../../hooks/useOnboarding'
import { KeyValuePair, UsedIn } from '../../types'
import { DotsThree, FunnelSimple } from '@phosphor-icons/react'
import { ApplicationContext } from '../../context'
import { VariableServicesContext } from '../../services'
import { GoTo } from '../GoTo'
import { PrettyNumber } from '../PrettyNumber'
import { InputAddButtons } from './InputAddButtons'
import { UseStage } from '../../services/useStage'
import { UsedInItem } from '../UsedInItem'
import FlagService from '../../services/flag'

const sortByOptions: KeyValuePair[] = [
    { name: 'Custom', value: 'created' },
    { name: 'Life-cycle stage', value: 'stage' },
    { name: 'Emissions', value: 'emissions' },
]

export const InputItems = (props: {
    product?: Product
    part?: Part
    stage?: UseStage
    parentInput?: Input
    disabled?: boolean
    nested?: boolean
    locked?: boolean
    className?: string
    onInputData?: (inputs: Input[]) => void
}) => {
    const context = useContext(ApplicationContext)
    const { productService, inputService } = useContext(VariableServicesContext)
    const { modelConfigUi } = useContext(ModelConfigContext)
    const [sortBy, setSortBy] = useState<BomSortByOption>(Utils.sortBomBy)
    const [dragTargetIndex, setDragTargetIndex] = useState<number>()
    const [dropTargetIndex, setDropTargetIndex] = useState<number>()
    const [dragCount, setDragCount] = useState<number>(0)
    const [otherFootprints, setOtherFootprints] = useState<UsedIn[]>([])
    const [shouldRenderList, setShouldRenderList] = useState<boolean>(false)
    const [hasFetchedInputs, setHasFetchedInputs] = useState<boolean>(false)
    const [inputList, setInputList] = useState<Input[]>([])
    const [inputIds, setInputIds] = useState<Set<string>>(new Set())
    const { fetchOnboarding, nextOnboardingStep, previousOnboardingStep } = useOnboarding()

    useEffect(() => fetchInputs(), [props.product?.uuid])

    useEffect(() => {
        const inputList = Array.from(inputIds.values())
            .map((i) => InputService.byId.get(i))
            .filter((i) => i)
            .map((i) => i!)
        const _inputs = inputList.sort((a, b) => {
            if (sortBy === 'stage') {
                if (!a.useStageCategory?.code) {
                    return 1
                }
                return a.useStageCategory?.code?.localeCompare(b.useStageCategory?.code || '') || -1
            } else if (sortBy === 'emissions') {
                const aCo2e = parseFloat(a.co2e || '0')
                const bCo2e = parseFloat(b.co2e || '0')
                return bCo2e - aCo2e
            }
            if (a.order === b.order) {
                return (a.created || 0) - (b.created || 0)
            }
            return a.order - b.order
        })
        setInputList(_inputs)
        props.onInputData?.(_inputs)
        setShouldRenderList(false)
    }, [inputIds, sortBy, shouldRenderList, setInputList])

    const _setInput = useCallback(
        (_newInputs?: Input | Input[]) => {
            if (!Array.isArray(_newInputs)) _newInputs = [_newInputs as Input]
            setInputIds((ibd) => {
                const m = new Set(ibd)
                _newInputs.forEach((i) => i.uuid && m.add(i.uuid))
                return m
            })
        },
        [inputIds],
    )

    const _removeInput = useCallback(
        (_removedInput: Input) => {
            setInputIds((ibd) => {
                const m = new Set(ibd)
                _removedInput.uuid && m.delete(_removedInput.uuid)
                return m
            })
        },
        [inputIds],
    )

    const _setInputs = useCallback((newInputs?: Input[]) => {
        const ibd = new Set<string>()
        newInputs?.forEach((i) => i.uuid && ibd.add(i.uuid))
        setInputIds(() => ibd)
    }, [])

    const reorderInputs = useCallback(() => {
        if (!inputIds.size || dragTargetIndex === undefined || dropTargetIndex === undefined) {
            return
        }
        if (dragTargetIndex === dropTargetIndex) {
            setDragTargetIndex(undefined)
            setDropTargetIndex(undefined)
            setDragCount(dragCount + 1)
            return
        }
        // console.log(dragTargetIndex, dropTargetIndex, ascending)
        let _inputs = [...inputList]
        const _dragTarget = _inputs.splice(dragTargetIndex, 1)[0]
        _inputs.splice(dropTargetIndex, 0, _dragTarget)
        _inputs = _inputs.map((i, _idx) => ({ ...i, order: _idx }))
        // console.log(_inputs)
        inputService.updateInputOrdering(_inputs).catch(Utils.errorToast)
        InputService.updateContext(_inputs)
        _setInputs(_inputs)
        setDragTargetIndex(undefined)
        setDropTargetIndex(undefined)
        setDragCount(dragCount + 1)
        setShouldRenderList(true)
    }, [inputService, inputIds, dragTargetIndex, dropTargetIndex, dragCount])

    const fetchInputs = useCallback(() => {
        if (!props.product?.uuid) return
        if (props.product?.type !== ProductFootprintType.LIVE) {
            setHasFetchedInputs(true)
            return
        }
        if (props.nested && props.parentInput) {
            setOtherFootprints(
                props.product?.usedIn?.filter(
                    (ui) => ui.type !== 'ActivityItem' && ui.uuid !== props.parentInput?.product?.uuid,
                ) || [],
            )
        }
        productService.getInputs(props.product.uuid).then((_inputs) => {
            _setInputs(_inputs)
            if (Utils.referrer === 'ob' && !context.stores.onboarding?.step) {
                fetchOnboarding().then(nextOnboardingStep)
                Utils.referrer = null
            }
            setHasFetchedInputs(true)
        })
    }, [
        props.product?.uuid,
        props.parentInput?.product?.uuid,
        props.nested,
        fetchOnboarding,
        nextOnboardingStep,
        _setInputs,
    ])

    const getDropTargetClassName = useCallback((): string => {
        if (dragTargetIndex === undefined || dropTargetIndex === undefined) {
            return ''
        }
        if (dropTargetIndex < dragTargetIndex) {
            return 'outline-top'
        } else if (dropTargetIndex > dragTargetIndex) {
            return 'outline-bottom'
        } else {
            return ''
        }
    }, [dragTargetIndex, dropTargetIndex])

    let inputCreationStage
    if (inputIds?.size > 1) {
        inputCreationStage = 4
    } else {
        const firstInput = inputList?.[0]
        inputCreationStage = 1
        if (firstInput?.name) {
            inputCreationStage = 2
            if (firstInput.sourceProduct?.uuid) {
                inputCreationStage = 3
                if (firstInput.useStageCategory) {
                    inputCreationStage = 4
                }
            }
        }
    }

    if (!hasFetchedInputs) {
        if (props.nested) {
            return (
                <div className='d-flex align-items-start'>
                    <Spinner size='sm' padding='px-2 pb-2' />
                </div>
            )
        }
        return <Spinner />
    }

    return (
        <>
            {!props.nested && Utils.justCreated(inputList?.[0], 5, 'minutes') && inputCreationStage < 4 && (
                <div className='alert bg-secondary bg-opacity-10 rounded-0 p-2 m-0 mb-2 shadow'>
                    {inputCreationStage === 1 && (
                        <>
                            <strong>Name this Input.</strong> This is a reference for this item in this product.
                        </>
                    )}
                    {inputCreationStage === 2 && (
                        <>
                            <strong>Add an Input.</strong> Inputs are the parts you buy from suppliers and the
                            processing steps. <em>{PartService.webTitle(true)} can be shared between footprints</em>.
                            <div>Click "Add {PartService.webTitle()}" to see all the options.</div>
                        </>
                    )}
                    {inputCreationStage === 3 && (
                        <>
                            <strong>Great job.</strong> You can adjust the quantity and unit, and also set the
                            Life-cycle stage.
                            <div>
                                If you want to delete a line and start over, click the{' '}
                                <DotsThree size={Utils.largeIconSize} className=' ' /> on the far right of the line.
                            </div>
                        </>
                    )}
                </div>
            )}
            {props.nested && props.product?.type === ProductFootprintType.LIVE && otherFootprints.length > 0 && (
                <div className='bg-white'>
                    <div className='bg-warning bg-opacity-10 border border-warning p-1 small'>
                        {props.product.name} is used in{' '}
                        <Tooltip
                            interactive={true}
                            closeOnInteraction={true}
                            trigger='hover'
                            tooltipContent={
                                <div className='d-flex flex-column align-items-start'>
                                    {otherFootprints.map((ui) => (
                                        <UsedInItem key={`ofp-${ui.uuid}`} usedIn={ui} />
                                    ))}
                                </div>
                            }
                            className='border-bottom border-dashed border-body text-height-1 fw-bold'
                        >
                            {otherFootprints.length} other {ProductService.elementTitle(otherFootprints.length !== 1)}
                        </Tooltip>
                        . Changes made here will affect all of them.
                    </div>
                </div>
            )}
            <div className='overflow-auto' aria-label='Model Inputs'>
                <table
                    hidden={!inputIds.size}
                    className={`table w-100 bg-white variable-table ${props.className || ''}`}
                >
                    <thead className='small'>
                        <tr>
                            <th style={{ width: '1rem' }} />
                            <th style={{ paddingLeft: '.7rem' }}>Name</th>
                            <th className='ps-2'>
                                <Tooltip
                                    hidden={!context.stores.onboarding}
                                    visible={context.stores.onboarding?.step === 3}
                                    interactive={true}
                                    placement='top-start'
                                    className='position-absolute'
                                    tooltipClassName='p-0 shadow-lg'
                                    tooltipContent={
                                        <div style={{ maxWidth: '20rem' }} className='text-start fw-normal p-2'>
                                            You thought{' '}
                                            {
                                                onboardingGuesses.find(
                                                    (og) => og.value === context.stores.onboarding?.guess,
                                                )?.description
                                            }{' '}
                                            might take up the most emissions. The answer is that it depends on lots of
                                            variables. Try changing the configurations in this column to see how the
                                            emissions change accordingly.
                                            <div className='mt-1 text-center'>
                                                <Button
                                                    onClick={previousOnboardingStep}
                                                    className='btn btn-sm underline-on-hover text-muted'
                                                >
                                                    Back
                                                </Button>
                                                <Button onClick={nextOnboardingStep} className='btn btn-sm btn-primary'>
                                                    Ok
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    &nbsp;
                                </Tooltip>
                                {ProductService.elementTitle()}
                            </th>
                            <th />
                            <th className='text-center'>Quantity</th>
                            <th className='text-end'>{FlagService.enabledFlags.has('EnableWeight') ? 'Weight' : ''}</th>
                            <th className='ps-2 text-nowrap'>Life-cycle stage {Utils.sortBomBy === 'stage' && '⏶'}</th>
                            <th className='text-center'>Data quality</th>
                            <th className='text-end'>
                                <UnitHeader unitSize='small' extraClassName='fw-bold' />{' '}
                                {Utils.sortBomBy === 'emissions' && '⏷'}
                            </th>
                            <th className='text-end'>
                                % of total
                                <Tooltip
                                    hidden={!context.stores.onboarding}
                                    visible={context.stores.onboarding?.step === 1}
                                    interactive={true}
                                    placement='top-end'
                                    className='position-absolute'
                                    tooltipClassName='p-0 shadow-lg'
                                    tooltipContent={
                                        <div style={{ maxWidth: '20rem' }} className='text-start fw-normal p-2'>
                                            This column shows you the percentage of emissions for each item.
                                            <div className='mt-1 text-center'>
                                                <Button onClick={nextOnboardingStep} className='btn btn-sm btn-primary'>
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                >
                                    &nbsp;
                                </Tooltip>
                            </th>
                            <th className='text-end'>
                                <Selector
                                    hideTextFilter={true}
                                    placement='left-start'
                                    className='p-1 rounded-1 me--1'
                                    hoverClassName=' '
                                    header={
                                        <span className='d-block text-start small text-muted fw-normal py-1 px-2'>
                                            Sort by
                                        </span>
                                    }
                                    options={sortByOptions}
                                    option={sortByOptions.find((sbo) => sbo.value === sortBy)}
                                    onSelect={(newValue) => {
                                        Utils.sortBomBy = newValue.value as BomSortByOption
                                        setSortBy(newValue.value as BomSortByOption)
                                    }}
                                    label={<FunnelSimple size={Utils.verySmallIconSize} />}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputList.map((input, idx) => (
                            <InputItem
                                key={`input-item-${input.uuid}`}
                                inputs={inputList}
                                lineType='input'
                                draggable={sortBy === 'created'}
                                onDragOver={(e) => {
                                    e?.preventDefault()
                                    setDropTargetIndex(idx)
                                }}
                                onDragStart={() => setDragTargetIndex(idx)}
                                onDragEnd={() => reorderInputs()}
                                dragCount={dragCount}
                                className={dropTargetIndex === idx ? getDropTargetClassName() : ''}
                                position={idx}
                                product={props.product}
                                input={input}
                                parentInput={props.parentInput}
                                count={inputIds.size}
                                disabled={props.disabled || props.locked || !props.product?.editable}
                                onChange={_setInput}
                                onCreate={_setInput}
                                onRemoved={_removeInput}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <div
                hidden={
                    !props.product?.editable ||
                    props.disabled ||
                    props.locked ||
                    props.product?.type !== ProductFootprintType.LIVE
                }
            >
                <div className='d-flex align-items-start justify-content-between'>
                    {props.product && (
                        <InputAddButtons product={props.product} inputIds={inputIds} onChange={_setInput} />
                    )}
                    {props.product?.quality === DataQuality.MODEL && !props.nested && (
                        <div className='w-50'>{modelConfigUi}</div>
                    )}
                </div>
                {/*<MonitorSyncBom product={props.product} onFinish={fetchInputs} />*/}
            </div>
            <div hidden={!props.nested || !props.product?.uuid}>
                {props.product?.type !== ProductFootprintType.LIVE && (
                    <div className='bg-info bg-opacity-10 py-2 px-3 small'>
                        <div hidden={!props.product?.uuid || props.product?.editable}>
                            You do not have permission edit {props.product?.name}
                            {props.product?.productOf?.uuid && `by ${props.product?.productOf?.name}`}
                            <GoTo
                                className='nt--2 ms-1'
                                url={ProductService.getProductUrl(props.product)}
                                preview={props.product}
                            />
                            .
                        </div>
                        <div hidden={!props.product?.editable}>
                            {(props.product?.type === ProductFootprintType.FACTOR ||
                                props.product?.type === ProductFootprintType.ELECTRICITY) && (
                                <div>
                                    This {ProductService.elementTitle().toLowerCase()}{' '}
                                    <GoTo
                                        className='nt--2'
                                        url={ProductService.getProductUrl(props.product)}
                                        preview={props.product}
                                    />{' '}
                                    uses an average emission factor of:
                                    <span className='ms-1 d-block'>
                                        <PrettyNumber num={props.product.factor?.quantity} />{' '}
                                        {props.product.factor?.unit?.name} of {props.product.factor?.footprint?.name} at{' '}
                                        <CO2e
                                            co2e={props.product.factor?.footprint?.co2e}
                                            unitSize='small'
                                            product={props.product.factor?.footprint}
                                            functionalUnit={props.product.factor?.footprint?.unit?.code}
                                            functionalUnitClassName='ms-1'
                                        />
                                    </span>
                                </div>
                            )}
                            {props.product?.type === ProductFootprintType.STATIC && (
                                <div>
                                    This {ProductService.elementTitle().toLowerCase()}{' '}
                                    <GoTo
                                        className='nt--2'
                                        url={ProductService.getProductUrl(props.product)}
                                        preview={props.product}
                                    />{' '}
                                    uses a documented emission factor of{' '}
                                    <CO2e
                                        className='fw-bold'
                                        co2e={props.product.co2e}
                                        unitSize='small'
                                        product={props.product}
                                        functionalUnit={props.product.unit?.name}
                                    />
                                    .
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div
                hidden={props.nested || props.disabled || props.locked || inputIds.size > 0}
                className='alert bg-secondary bg-opacity-10 rounded-0 p-2 m-0 mt-2 shadow'
            >
                👆 <strong>Get started by adding some inputs.</strong>
                <div>
                    Inputs are materials that go into your product ("Upstream"), processing steps ("Direct"), or
                    emissions related to the use of your product ("Downstream").
                </div>
            </div>
        </>
    )
}
