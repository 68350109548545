import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Spinner from './Spinner'
import ProductService, { Product } from '../services/product'
import CO2e from './CO2e'
import { QrCode } from './QrCode'
import Card from './Card'
import Utils from '../services/utils'
import NotFound from './NotFound'
import { DocumentList } from './Input/FileUploader'
import { Img } from './Img'
import { CategoryModel } from '../services/category-model'
import AuthenticationService from '../services/authentication'
import { VerifiedIcon } from './Icons/VerifiedIcon'
import { TaxonomyLabel } from './TaxonomyLabel'
import { DataQualityHeader, QualitySummary } from './User/QualitySummary'
import { ProductUsage } from './Product/ProductUsage'
import { ApplicationContext } from '../context'
import { VariableServicesContext } from '../services'
import { GoTo } from './GoTo'
import { LifecycleBar } from './Product/LifecycleBar'
import { InventoryService } from '../services/inventory'
import HttpService from '../services/http'
import { DebugData } from './Footnotes'
import { InventoryToggle } from './InventoryToggle'
import LocationService from '../services/location'

import { Co2eDisplay } from '../types'
import { TagList } from './TagList'

export const Passport = (props: {
    className?: string
    productId?: string
    product?: Product
    isModal?: boolean
    co2eDisplay?: Co2eDisplay
    onProduct?: (product?: Product) => void
}) => {
    const context = useContext(ApplicationContext)
    const { uiService, productService, categoryModelService } = useContext(VariableServicesContext)
    const [product, setProduct] = useState<Product | undefined>(props.product)
    const [categoryModel, setCategoryModel] = useState<CategoryModel>()
    const [notFound, setNotFound] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(false)

    useEffect(() => fetchPassport(props.productId), [props.productId])

    useEffect(() => {
        if (props.product) setProduct(props.product)
    }, [props.product])

    useEffect(() => {
        props.onProduct?.(product)
        uiService.showLoggedOutSidebar(true)

        if (product?.categoryModel?.uuid) {
            categoryModelService.getModelById(product.categoryModel.uuid).then(setCategoryModel)
        }

        if (product) {
            if (InventoryService.byId.has(product?.uuid || '')) {
                uiService.setInventoryNavPath(InventoryService.productToInventory(product))
            } else {
                uiService.setNavPath(ProductService.webRootDatabase)
            }
        }

        return () => {
            uiService.resetNavPath()
        }
    }, [product])

    const fetchPassport = useCallback((productId?: string) => {
        if (productId) {
            productService
                .getPassport(productId, undefined, true)
                .then(setProduct)
                .catch(() => setNotFound(true))
        }
    }, [])

    const description = useMemo(() => product?.description?.trim(), [product?.description])

    const loc = useMemo(() => LocationService.getNodeLocation(product), [product?.location, product?.geoLocation])

    const extraData = useMemo(() => {
        if (!product) return null
        return (
            <>
                <CO2e
                    className='d-block text-nowrap h5 my-2'
                    co2e={props.co2eDisplay === 'Total' ? product?.co2e : product?.upstreamCo2e || product?.co2e}
                    product={product}
                    numberClassName='fs-4'
                    unitsClassName='small'
                    functionalUnit={product.unit?.code}
                    functionalUnitClassName='ms-1'
                    unitSize='small'
                />
                <LifecycleBar
                    className='my-2 mx-auto mx-sm-0'
                    product={product}
                    co2eDisplay={props.co2eDisplay}
                    percentBarProps={{ extraClassName: 'bg-dark bg-opacity-10', height: '8px', showLabels: true }}
                />
                <div
                    role='button'
                    tabIndex={0}
                    className={[
                        'small ps-2 border-start border-3 my-3 text-start text-break overflow-hidden',
                        description && description.length > 350 ? 'gradient-text' : '',
                        expanded ? 'expanded' : '',
                    ].join(' ')}
                    style={{ height: !expanded && description && description.length > 350 ? '120px' : 'auto' }}
                    onClick={() => setExpanded(!expanded)}
                    onKeyDown={(e) => e.key === 'Enter' && setExpanded(!expanded)}
                >
                    {description}
                </div>
                {product?.startDate && (
                    <div className='small'>
                        Time period:
                        <span className='text-alt-font bg-light p-1'>{Utils.toDateString(product?.startDate)}</span>
                        {product?.endDate && (
                            <>
                                {' '}
                                -{' '}
                                <span className='text-alt-font bg-light p-1'>
                                    {Utils.toDateString(product?.endDate)}
                                </span>
                            </>
                        )}
                    </div>
                )}
                <div className='mt-3 d-flex align-items-center gap-1'>
                    <div hidden={!loc?.uuid} className='border px-1 align-items-center gap-1'>
                        {LocationService.getLocationTypeIcon(loc)} {LocationService.getLocationName(loc)}
                    </div>
                    <TagList product={product} />
                </div>
                {categoryModel?.uuid && (
                    <div className='bg-light rounded-2 p-2 small'>
                        <strong>Configuration options</strong>
                        {categoryModel?.config?.map((mc) => {
                            return <div>{mc.description}</div>
                        })}
                    </div>
                )}
            </>
        )
    }, [product, categoryModel, expanded, loc?.uuid, loc?.name, loc?.type, props.co2eDisplay])

    const externalLink = useMemo(() => {
        if (!product?.externalLink) return null
        return (
            <>
                <hr />
                <GoTo
                    linkClassName='text-body text-primary-hover text-word-break-all small'
                    useAHref={true}
                    target='_blank'
                    url={product.externalLink}
                >
                    {product.externalLink}
                </GoTo>
            </>
        )
    }, [product?.externalLink])

    const dataSources = useMemo(() => {
        if (!product?.dataSources || !product?.dataSources.length) return null
        return (
            <>
                <hr />
                {product?.dataSources?.map((ds) => {
                    return (
                        <div key={`pds-${ds.uuid}`}>
                            <GoTo
                                linkClassName='text-body text-primary-hover small'
                                useAHref={true}
                                target='_blank'
                                url={ds.url}
                            >
                                {ds.longName || ds.name}
                            </GoTo>
                            <div>{ds.notes}</div>
                        </div>
                    )
                })}
            </>
        )
    }, [product?.dataSources])

    if (notFound) {
        return <NotFound className={props.className} />
    }

    if (!product) {
        return (
            <Card className={props.className}>
                <Spinner />
            </Card>
        )
    }

    return (
        <>
            <div className='d-flex align-items-center gap-2 pe-2 mb-2'>
                <TaxonomyLabel taxonomy={product.taxonomy} followLink={!HttpService.getSubdomain()} />
                <div className='ms-auto' hidden={props.isModal}>
                    <InventoryToggle
                        product={product}
                        showOnProduct={false}
                        onCreated={() => fetchPassport(product.uuid)}
                    />
                </div>
            </div>
            <Card className={props.className || 'shadow'}>
                <div className='d-flex flex-column flex-sm-row'>
                    <div className='flex-grow-1 d-flex align-items-start mx-auto'>
                        <Img src={product.productImageUrl} className='product-image me-3 mb-3' alt={product.name} />
                        <div
                            className={[product.productImageUrl !== undefined ? '' : 'text-center text-sm-start'].join(
                                ' ',
                            )}
                        >
                            <span className='d-flex flex-wrap align-items-center gap-1'>
                                {product.labels?.includes('EPD') && <span className='label-epd'>EPD</span>}
                                {ProductService.isLiveProduct(product) && (
                                    <span className='label-live position-relative me-1' style={{ top: '5px' }}>
                                        Live
                                    </span>
                                )}
                                <h1 className='fs-3 m-0'>{product?.name}</h1>
                                {product.isVerified && <VerifiedIcon className='nt-1' size={18} />}
                                {product.isSolution && <span className='label-solution ms-1 mt-1'>Solution</span>}
                            </span>
                            <h6 className='m-0' hidden={!product.productOf?.uuid}>
                                by {product.productOf?.name}
                            </h6>
                            <div className='d-none d-sm-block'>{extraData}</div>
                        </div>
                    </div>
                    <div className='d-block d-sm-none text-center text-sm-start mb-3'>{extraData}</div>
                    <div>
                        <DataQualityHeader />
                        <QualitySummary product={product} className='d-block' />
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between gap-2 mt-3'>
                    <DocumentList
                        className='mt-2 mb--2 d-flex flex-wrap align-items-center flex-grow-1'
                        nodeId={product.uuid}
                        files={product.documents}
                        documentClassName='d-block mb-1 max-w-50 me-2 mb-2'
                        onUnAuthClick={() => AuthenticationService.sendUserToAuth()}
                    />
                    {product?.uuid && (
                        <QrCode data={ProductService.getPassportUrl(product, props.productId)} size={30} />
                    )}
                </div>
                {externalLink}
                {dataSources}
            </Card>
            <div hidden={!context.stores.user?.uuid} className='small bg-body p-2 rounded-2 mt-3'>
                <ProductUsage product={product} />
            </div>
            <DebugData node={product} extraValues={[product?.syncId]} />
        </>
    )
}
